import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/core/services/cart.service';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  // animations: [
  //   trigger('openClose', [
  //     state(
  //       'star',
  //       style({
  //         transform: 'scale(1)'
  //       }),
  //     ),
  //     state(
  //       'end',
  //       style({
  //         transform: 'scale(1.2)'
  //       }),
  //     ),
  //     transition('star => end', [animate('100ms')]),
  //     transition('end => star', [animate('100ms')]),
  //   ]),
  // ],
})
export class ShoppingCartComponent implements OnInit {
  @Input('isWhite') isWhite = false;
  @Output('onClick') onClick: EventEmitter<void> = new EventEmitter<void>();
  subscription: Subscription;
  productsCount: number | null = null;
  animate = false;

  constructor(private cartService: CartService, public i18nService: I18nService,) { }

  ngOnInit(): void {
    this.subscription = this.cartService.cart$.subscribe(res => {
      this.playAnimation(res.count);
      this.productsCount = res.count;
    });
  }

  playAnimation(count: number) {
    if (this.productsCount !== null && this.productsCount !== count) {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 250);
    }
  }

  showCart() {
    if (!this.productsCount || this.productsCount === 0) return;
    this.onClick.emit();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
