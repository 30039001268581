<div class="shopping-cart">
  <mat-icon
    id="cart"
    class="shopping-cart__icon"
    [matBadge]="productsCount"
    matBadgeColor="warn"
    matBadgeSize="small"
    [matBadgeHidden]="productsCount === 0"
    (click)="showCart()"
    [ngClass]="{'shopping-cart__icon_is-animate': animate, 'shopping-cart__icon_is-white': isWhite}"
  >
    shopping_cart
  </mat-icon>
  <label for="cart" class="shopping-cart__label" [ngClass]="{'shopping-cart__label_is-white': isWhite}">
    {{i18nService.data.global.cart}}
  </label>
</div>
