<mat-sidenav-container class="sidenav-container" [ngClass]="{'sidenav-container_z-index_1': displayCart}">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="innerWidth <= 1024 ? 'dialog' : 'navigation'"
      [mode]="innerWidth <= 1024 ? 'over' : 'side'"
      [opened]="innerWidth > 1024">
    <mat-toolbar class="logo">
      <app-logo (onNav)="toggleNavIconOnMobile(drawer)"></app-logo>
    </mat-toolbar>
    <app-navbar (onNav)="toggleNavIconOnMobile(drawer)"></app-navbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <button
      type="button"
      class="toolbar__burger-button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      *ngIf="innerWidth <= 1024">
      <mat-icon class="toolbar__icon" [ngClass]="{'toolbar__icon_is-black': router.url.split('?')[0] !== '/main/index'}" aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <app-shopping-cart
      class="toolbar__cart"
      [isWhite]="router.url.split('?')[0] === '/main/index'"
      (onClick)="openModal()"
    ></app-shopping-cart>
    <div class="content">
      <ng-content></ng-content>
      <p-dialog
        class="shopping-cart-dialog"
        [(visible)]="displayCart"
        [modal]="true"
        [style]="{width: '80vw'}"
        [breakpoints]="{'1024px': '95vw'}"
        [draggable]="false"
        [resizable]="false"
        [autoZIndex]="false"
        [rtl]="currLang === 'ar'"
        (onHide)="hideModal($event)"
      >
        <mat-horizontal-stepper *ngIf="displayCart && innerWidth >= 1024" [dir]="direction" [linear]="true" (selectionChange)="tryNext($event)" #stepper>
          <mat-step  label="Step 1" state="cart">
            <ng-template matStepLabel>{{i18nService.data.order.yourCart}}</ng-template>
            <app-cart-table
              (onClickProduct)="displayCart = false"
              (onRemoveAll)="hideModal($event)"
            ></app-cart-table>
            <div class="stepper__footer">
              <button mat-button matStepperNext>{{i18nService.data.order.next}}</button>
            </div>
          </mat-step>
          <mat-step label="Step 2" state="address" [stepControl]="customerForm" errorMessage="Enter your information.">
            <ng-template matStepLabel>{{i18nService.data.order.fillOutYourInformation}}</ng-template>
            <form id="product-form" [formGroup]="customerForm" class="stepper__form">
              <mat-form-field appearance="fill" class="stepper__name-field">
                <mat-label>{{i18nService.data.order.client.fullName}}</mat-label>
                <input matInput formControlName="name" [errorStateMatcher]="matcher"
                      placeholder={{i18nService.data.order.client.fullNamePlaceholder}}>
                <mat-error *ngIf="customerForm.get('name')?.hasError('required')">
                  {{i18nService.data.order.client.fullNameError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__phone-field">
                <mat-label>{{i18nService.data.order.client.phone}}</mat-label>
                <input matInput formControlName="phone" [errorStateMatcher]="matcher"
                      placeholder={{i18nService.data.order.client.phonePlaceholder}}>
                <mat-error *ngIf="customerForm.get('phone')?.hasError('required')">
                  {{i18nService.data.order.client.phoneError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
                <mat-error *ngIf="customerForm.get('phone')?.hasError('pattern')">
                  {{i18nService.data.order.client.invalidPhoneNumber}}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__address-field">
                <mat-label>{{i18nService.data.order.client.address}}</mat-label>
                <input matInput formControlName="address" [errorStateMatcher]="matcher"
                      placeholder={{i18nService.data.order.client.addressPlaceholder}}>
                <mat-error *ngIf="customerForm.get('address')?.hasError('required')">
                  {{i18nService.data.order.client.addressError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__state-field">
                <mat-label>{{i18nService.data.order.client.state}}</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of states" [value]="state">
                    {{state}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="customerForm.get('state')?.hasError('required')">
                  {{i18nService.data.order.client.addressError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__country-field">
                <mat-label>{{i18nService.data.order.client.country}}</mat-label>
                <input matInput formControlName="country" [errorStateMatcher]="matcher"
                      placeholder="Country" [readonly]="true">
              </mat-form-field>
            </form>
            <div class="stepper__footer">
              <button mat-button matStepperPrevious>{{i18nService.data.order.back}}</button>
              <button mat-button matStepperNext>{{i18nService.data.order.next}}</button>
          </div>
          </mat-step>
          <mat-step label="Step 3" state="finish">
            <ng-template matStepLabel (click)="submitClientInfo()">{{i18nService.data.order.done}}</ng-template>
            <div class="stepper__order-now">
              <h1 class="stepper__order-now-title">{{i18nService.data.order.submitTitle}}</h1>
              <h4 class="stepper__order-now-subtitle">
                {{i18nService.data.order.submitSubtitle}}
              </h4>
              <div class="stepper__order-now-price">
                {{i18nService.data.order.totalPrice}} {{cart.price | price}}
              </div>
              <div class="stepper__order-now-info">
                {{message}}
              </div>
            </div>
            <div class="stepper__footer">
              <button *ngIf="!isOrdering" mat-button matStepperPrevious>{{i18nService.data.order.back}}</button>
              <button *ngIf="!isOrdering" mat-button (click)="createOrder()">{{i18nService.data.order.submit}}</button>
              <mat-progress-spinner
                *ngIf="isOrdering"
                class="stepper__footer-spinner"
                diameter="30"
                mode="indeterminate"
                value="80">
              </mat-progress-spinner>
            </div>
          </mat-step>

          <!-- Icon overrides. -->
          <ng-template matStepperIcon="cart">
            <mat-icon>shopping_cart</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="address">
            <mat-icon>home</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="finish">
            <mat-icon>done</mat-icon>
          </ng-template>
        </mat-horizontal-stepper>
        <mat-vertical-stepper *ngIf="displayCart && innerWidth < 1024" [dir]="direction" [linear]="true" (selectionChange)="tryNext($event)" #stepper>
          <mat-step  label="Step 1" state="cart">
            <ng-template matStepLabel>{{i18nService.data.order.yourCart}}</ng-template>
            <app-cart-table
              (onClickProduct)="displayCart = false"
              (onRemoveAll)="hideModal($event)"
            ></app-cart-table>
            <div class="stepper__footer">
              <button mat-button matStepperNext>{{i18nService.data.order.next}}</button>
            </div>
          </mat-step>
          <mat-step label="Step 2" state="address" [stepControl]="customerForm" errorMessage="Enter your information.">
            <ng-template matStepLabel>{{i18nService.data.order.fillOutYourInformation}}</ng-template>
            <form id="product-form" [formGroup]="customerForm" class="stepper__form">
              <mat-form-field appearance="fill" class="stepper__name-field">
                <mat-label>{{i18nService.data.order.client.fullName}}</mat-label>
                <input matInput formControlName="name" [errorStateMatcher]="matcher"
                      placeholder={{i18nService.data.order.client.fullNamePlaceholder}}>
                <mat-error *ngIf="customerForm.get('name')?.hasError('required')">
                  {{i18nService.data.order.client.fullNameError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__phone-field">
                <mat-label>{{i18nService.data.order.client.phone}}</mat-label>
                <input matInput formControlName="phone" [errorStateMatcher]="matcher"
                      placeholder={{i18nService.data.order.client.phonePlaceholder}}>
                <mat-error *ngIf="customerForm.get('phone')?.hasError('required')">
                  {{i18nService.data.order.client.phoneError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
                <mat-error *ngIf="customerForm.get('phone')?.hasError('pattern')">
                  {{i18nService.data.order.client.invalidPhoneNumber}}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__address-field">
                <mat-label>{{i18nService.data.order.client.address}}</mat-label>
                <input matInput formControlName="address" [errorStateMatcher]="matcher"
                      placeholder={{i18nService.data.order.client.addressPlaceholder}}>
                <mat-error *ngIf="customerForm.get('address')?.hasError('required')">
                  {{i18nService.data.order.client.addressError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__state-field">
                <mat-label>{{i18nService.data.order.client.state}}</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of states" [value]="state">
                    {{state}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="customerForm.get('state')?.hasError('required')">
                  {{i18nService.data.order.client.addressError}} <strong>{{i18nService.data.order.client.required}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="stepper__country-field">
                <mat-label>{{i18nService.data.order.client.country}}</mat-label>
                <input matInput formControlName="country" [errorStateMatcher]="matcher"
                      placeholder="Country" [readonly]="true">
              </mat-form-field>
            </form>
            <div class="stepper__footer">
              <button mat-button matStepperPrevious>{{i18nService.data.order.back}}</button>
              <button mat-button matStepperNext>{{i18nService.data.order.next}}</button>
          </div>
          </mat-step>
          <mat-step label="Step 3" state="finish">
            <ng-template matStepLabel (click)="submitClientInfo()">{{i18nService.data.order.done}}</ng-template>
            <div class="stepper__order-now">
              <h1 class="stepper__order-now-title">{{i18nService.data.order.submitTitle}}</h1>
              <h4 class="stepper__order-now-subtitle">
                {{i18nService.data.order.submitSubtitle}}
              </h4>
              <div class="stepper__order-now-price">
                {{i18nService.data.order.totalPrice}} {{cart.price | price}}
              </div>
              <div class="stepper__order-now-info">
                {{message}}
              </div>
            </div>
            <div class="stepper__footer">
              <button *ngIf="!isOrdering" mat-button matStepperPrevious>{{i18nService.data.order.back}}</button>
              <button *ngIf="!isOrdering" mat-button (click)="createOrder()">{{i18nService.data.order.submit}}</button>
              <mat-progress-spinner
                *ngIf="isOrdering"
                class="stepper__footer-spinner"
                diameter="30"
                mode="indeterminate"
                value="80">
              </mat-progress-spinner>
            </div>
          </mat-step>

          <!-- Icon overrides. -->
          <ng-template matStepperIcon="cart">
            <mat-icon>shopping_cart</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="address">
            <mat-icon>home</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="finish">
            <mat-icon>done</mat-icon>
          </ng-template>
        </mat-vertical-stepper>
      </p-dialog>
    </div>
    <app-footer *ngIf="showFooter"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
