import { Component, OnInit } from '@angular/core';
import { ENGLISH, I18nService } from 'src/app/core/services/i18n.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.scss']
})
export class TopSectionComponent implements OnInit {
  whatsappUrl = 'https://wa.me/96171327184';
  subscription: Subscription;
  lang: string;

  constructor(public i18nService: I18nService,
    private settingsService: SettingsService,
    private router: Router) { }

  ngOnInit(): void {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    );
  }

  get topSectionTitle() {
    return this.lang === ENGLISH
      ? this.settingsService.getTopSectionTitle.settingValue
      : this.settingsService.getTopSectionTitleAr.settingValue;
  }

  get topSectionDescription() {
    return this.lang === ENGLISH
      ? this.settingsService.getTopSectionDescription.settingValue
      : this.settingsService.getTopSectionDescriptionAr.settingValue;
  }

  contactUs() {
    window.open(this.whatsappUrl, '_blank');
  }

  goToGallery() {
    this.router.navigateByUrl('/main/products');
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
