// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCdLdpOd-J0Mwq6QTzy4pPpa2T3iwwSNz8",
    authDomain: "bella-balea.firebaseapp.com",
    databaseURL: "https://bella-balea.firebaseio.com",
    projectId: "bella-balea",
    storageBucket: "bella-balea.appspot.com",
    messagingSenderId: "73583757685",
    appId: "1:73583757685:web:6a974d11b6b26b2790ab3c",
    measurementId: "G-SCFL5FKB0K"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
