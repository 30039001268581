import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ProductsService } from 'src/app/core/services/products.service';
import { Subscription, Observable } from 'rxjs';
import { Product } from 'src/app/core/models/product';
import { ActivatedRoute, Router } from '@angular/router';
import { InQueryParams } from 'src/app/core/models/query-params';
import { I18nService } from 'src/app/core/services/i18n.service';
import Category, { Subcategory } from 'src/app/core/models/category';
import { capitalize, dasherize } from 'src/app/core/utils/capitalize-query-params';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { BrandsService } from 'src/app/core/services/brands.service';
import { Chip } from 'src/app/core/models/chip';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @ViewChild('inputSearch') inputSearchElementView: ElementRef;
  items$: Observable<Product[]>;
  categories: Array<Category> = [];
  selectedCategory: Category = null;
  selectedSubcategory: Subcategory = null;
  params: InQueryParams = null;
  inQueryParams$: Observable<InQueryParams> = null;
  subscription: Subscription;
  langSubscription: Subscription;
  lang: string = 'en';
  loading$!: Observable<boolean>;
  footer: Element;

  constructor(private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private categoriesService: CategoriesService,
    private brandsService: BrandsService,
    public i18nService: I18nService,) { }

  ngOnInit(): void {
    this.categories = this.categoriesService.categories;
    this.inQueryParams$ = this.route.queryParams;
    this.langSubscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      });

    this.subscription = this.route.queryParams.subscribe(
      params => {
        this.params = params as InQueryParams;
        if (this.params.search && this.params.search !== '') {
          this.selectedCategory = null;
          this.selectedSubcategory = null;
          this.items$ = this.productsService.searchProducts(this.params.search, this.lang);
        } else {
          this.selectedCategory = this.params.category ? this.categories.find(cat => cat.name === capitalize(this.params.category)) : null;
          if (this.selectedCategory?.subcategories) {
            this.selectedSubcategory = this.selectedCategory.subcategories.find(sub => sub.name === capitalize(this.params.subcategory));
          } else {
            this.selectedSubcategory = null;
          }
          this.productsService.first(this.params);
          this.items$ = this.productsService.data!;
        }
        this.loading$ = this.productsService.loading;
      }
    );
  }

  get selectedBrand() {
    if (this.params.brand) {
      return this.brandsService.brands.find(({ uid }) => uid === this.params.brand).name;
    }
    return null;
  }

  get chips() {
    let chips: Chip[] = [];
    if (this.selectedBrand) {
      chips.push({
        label: this.selectedBrand,
        onRemove: () => this.removeBrandChip()
      })
    }
    if (this.selectedCategory) {
      chips.push({
        label: this.selectedCategory.name,
        onRemove: () => this.removeCategoryChip()
      })
    }
    if (this.selectedSubcategory) {
      chips.push({
        label: this.selectedSubcategory.name,
        onRemove: () => this.removeSubcategoryChip()
      })
    }
    if (this.params.search) {
      chips.push({
        label: this.params.search.length > 15 ? this.params.search.slice(0, 15) + '...' : this.params.search,
        onRemove: () => this.removeSearchChip()
      })
    }
    return chips;
  }

  onClickCategory(category: Category) {
    this.selectedCategory = this.selectedCategory?.name === category?.name ? null : category;
    this.selectedSubcategory = null;
    let { brand } = this.params;
    let queryParams = {
      category: dasherize(this.selectedCategory?.name),
      subcategory: null,
      brand
    }
    this.router.navigate(['/main/products'], { queryParams });

  }

  onClickSubcategory(subcategories: Subcategory) {
    this.selectedSubcategory = this.selectedSubcategory === subcategories ? null : subcategories;
    let { category, brand } = this.params;
    let queryParams = {
      category,
      subcategory: dasherize(this.selectedSubcategory?.name),
      brand
    }
    this.router.navigate(['/main/products'], { queryParams });
  }

  searchProduct($event: KeyboardEvent, search: string) {
    if ($event.key === 'Enter' && search.trim() !== '') {
      this.search(search.trim());
      this.inputSearchElementView.nativeElement.value = '';
    }
  }

  search(search: string) {
    let queryParams = {
      search
    };
    this.router.navigate(['/main/products'], { queryParams });
  }

  removeBrandChip() {
    let { brand, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/main/products'], { queryParams });
  }

  removeCategoryChip() {
    let { category, subcategory, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/main/products'], { queryParams });
  }

  removeSubcategoryChip() {
    let { subcategory, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/main/products'], { queryParams });
  }

  removeSearchChip() {
    let { search, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/main/products'], { queryParams });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(_: any) {
    if (this.router.url.startsWith('/main/products')) {
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      let max = document.documentElement.scrollHeight;
      if(max - pos < 100) {
        this.productsService.next(this.params);
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.langSubscription?.unsubscribe();
  }
}
