import { Component, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ENGLISH, I18nService } from 'src/app/core/services/i18n.service';
import { Router } from '@angular/router';
import { AppErrorStateMatcher } from 'src/app/shared/utils/error-state-matcher';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { OrderService, STATES } from 'src/app/core/services/order.service';
import { CartService } from 'src/app/core/services/cart.service';
import { State } from 'src/app/core/models/order';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false, showError: true}
  }]
})
export class MainNavComponent {
  subscription: Subscription;
  currLang: string;
  public innerWidth: any;
  displayCart = false;
  isLinear = false;
  isOrdering = false;

  matcher = new AppErrorStateMatcher();

  customerForm = this.fb.group({
    name: ['', Validators.required],
    phone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
    address: ['', Validators.required],
    state: ['', Validators.required],
    country: ['Lebanon'],
  });

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public i18nService: I18nService,
    public router: Router,
    private fb: FormBuilder,
    private cartService: CartService,
    private orderService: OrderService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.currLang = lang;
      }
    );
  }

  get showFooter() {
    return !this.router.url.startsWith('/main/products');
  }

  get states() {
    return [...STATES];
  }

  get cart() {
    return this.cartService.cart;
  }

  get message() {
    return this.currLang === ENGLISH
      ? this.settingsService.getOrderText.settingValue
      : this.settingsService.getOrderTextAr.settingValue
  }

  get direction() {
    return this.currLang === ENGLISH
      ? 'ltr'
      : 'rtl'
  }

  submitClientInfo() {
    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      return;
    }
    localStorage.setItem('name', this.customerForm.get('name').value);
    localStorage.setItem('phone', this.customerForm.get('phone').value);
    localStorage.setItem('address', this.customerForm.get('address').value);
    localStorage.setItem('state', this.customerForm.get('state').value);
    localStorage.setItem('country', this.customerForm.get('country').value);
  }

  openModal() {
    this.displayCart = true;
    this.customerForm.get('name')?.setValue(localStorage.getItem('name') ?? '');
    this.customerForm.get('phone')?.setValue(localStorage.getItem('phone') ?? '');
    this.customerForm.get('address')?.setValue(localStorage.getItem('address') ?? '');
    this.customerForm.get('state')?.setValue(localStorage.getItem('state') ?? '');
    this.customerForm.get('country')?.setValue(localStorage.getItem('country') ?? 'Lebanon');
    if (localStorage.getItem('phone')) {
      for (var i in this.customerForm.controls) {
        this.customerForm.controls[i].markAsTouched();
      }
    }
  }

  hideModal($event: any) {
    this.displayCart = false;
    this.customerForm.reset();
  }

  tryNext($event) {
    if ($event.selectedIndex === 2) {
      this.submitClientInfo();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  toggleNavIconOnMobile(drawer) {
    if (this.innerWidth <= 1024) {
      drawer.toggle();
    }
  }

  async createOrder() {
    try {
      this.isOrdering = true;
      let fullName = localStorage.getItem('name');
      let phone = localStorage.getItem('phone');
      let address = localStorage.getItem('address');
      let state = localStorage.getItem('state') as State;
      let order = await this.orderService.createOrder({
        productOrders: JSON.stringify(this.cart.productOrders),
        createdAt: new Date(),
        fullName,
        phone,
        address,
        state,
        country: 'Lebanon',
        isArchived: false
      });
      await this.orderService.createNewOrder(order.id);
      let orderSetting = this.settingsService.getOrders;
      await this.settingsService.updateSetting({
        ...orderSetting!,
        settingValue: ++(orderSetting!.settingValue as number)
      });
      await this.orderService.createNotification();
      this.cartService.clearCart();
      this.displayCart = false;
      this.customerForm.reset();
    } finally {
      this.isOrdering = false
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
