import { Injectable } from '@angular/core';
import Category from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private iconsPath = './assets/images';
  constructor() { }

  get categories(): Array<Category> {
    return [
      { name: 'Hair Care', icon: `${this.iconsPath}/hair.svg`, subcategories: null },
      { name: 'Skin Care', icon: `${this.iconsPath}/skin.svg`, subcategories: null },
      { name: 'Lips Care', icon: `${this.iconsPath}/lips.svg`, subcategories: null },
      { name: 'Mouth Care', icon: `${this.iconsPath}/mouth.svg`, subcategories: null },
      { name: 'Body Care', icon: `${this.iconsPath}/body.svg`, subcategories: null },
      { name: 'Hand Care', icon: `${this.iconsPath}/hand.svg`, subcategories: null },
      { name: 'Foot Care', icon: `${this.iconsPath}/foot.svg`, subcategories: null },
      { name: 'Perfumes', icon: `${this.iconsPath}/perfume.svg`, subcategories: null },
      { name: 'Makeup', icon: `${this.iconsPath}/makeup.svg`,
        subcategories: [
          { name: 'Face', icon: `${this.iconsPath}/face_makeup.svg` },
          { name: 'Eyes', icon: `${this.iconsPath}/eyes_makeup.svg` },
          { name: 'Lips', icon: `${this.iconsPath}/lips_makeup.svg` },
          { name: 'Nails', icon: `${this.iconsPath}/nail_makeup.svg` },
        ]
      },
      { name: 'Food Supplements', icon: `${this.iconsPath}/supplement.svg`, subcategories: null },
      { name: 'Cleaning Tools', icon: `${this.iconsPath}/cleaning.svg`, subcategories: null },
      { name: 'Others', icon: `${this.iconsPath}/ellipsis.svg`, subcategories: null }
    ];
  }
}
