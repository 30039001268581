<div class="language-switch" dir={{dir}}>
  <fa-icon
    class="social-media-bar_icon"
    [icon]="faLanguage"
    size="lg"
  ></fa-icon>
  <mat-form-field>
    <!-- <mat-label>Language</mat-label> -->
    <mat-select [(value)]="currentLang" (selectionChange)="selectLang($event)">
      <mat-option
        *ngFor="let lang of languages"
        value={{lang.languageCode}}
      >
        {{dir === 'rtl' ? lang.flag : ''}} {{lang.name}} {{dir === 'ltr' ? lang.flag : ''}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
