import { ITranslationModel } from 'src/app/core/translations/translation-model';

export const Transitions: ITranslationModel = {
  'en': {
    global: {
      back: "Back",
      lbp: "LBP",
      usd: "USD",
      brandsTitle: "Brands",
      brandsSubtitle: "Find products by brands",
      productsTitle: "",
      productsSubtitle: "",
      noProductsFound: "No Products Found!",
      noProductFound: "No Product Found!",
      noBrandsFound: "No Brands Found!",
      noAwesomeFound: "No Awesome Product Found!",
      noBestsFound: "No Best Seller Products Found!",
      search: "Search",
      soldOut: "Sold Out",
      goToHome: "Go To Home Page",
      contactUs: "Contact us",
      cart: "Cart",
      loading: "Loading..."
    },
    homePage: {
      topSectionTitle: "Bella Balea",
      topSectionSubtitle: "Bella Balea website is designed for high-quality German beauty products to make you beautiful and charming all day long and also to achieve your external beauty as you feel your internal beauty.  A special site dedicated to wholesale customers where you can find all your requests of body care, hair, skin, hands, and feet at the lowest prices and the best quality.",
      topSectionButton: "go to gallery",
      newProducts: "New Products",
      newProductsSubtitle: "Show our new awesome products",
      bestSeller: "Best Sellers",
      bestSellerSubtitle: "Show our new awesome products",
      showProduct: "Show Product",
      quoteTitle: "About Bella Balea"
    },
    productPage: {
      addToCart: "Add To Cart"
    },
    navbar: {
      home: "Home",
      hairCare: "Hair Care",
      skinCare: "Skin Care",
      lipsCare: "Lips Care",
      mouthCare: "Mouth Care",
      bodyCare: "Body Care",
      handCare: "Hand Care",
      footCare: "Foot Care",
      makeup: {
        title: "Makeup",
        subcategories: {
          face: "Face",
          eyes: "Eyes",
          lips: "Lips",
          nails: "Nails"
        }
      },
      perfumes: "Perfumes",
      brands: "Brands",
      foodSupplements: "Food Supplements",
      cleaningTools: "Cleaning Tools",
      others: "Others"
    },
    footer: {
      aboutUs: "About Us",
      quickContact: "Quick Contact",
      quickLinks: "Quick Links"
    },
    order: {
      success: "Success",
      addedToCart: "The product has been added to your cart.",
      yourCart: "Your Cart",
      fillOutYourInformation: "Fill Out Your Information",
      done: "Done",
      submit: "Order Now!",
      submitTitle: "Order Now",
      submitSubtitle: "Your order is ready to proceed",
      totalPrice: "Total Price: ",
      back: "Back",
      next: "Next",
      cart: {
        name: "Name",
        image: "Image",
        price: "Price",
        count: "Count",
        total: "Total",
        totals: "Totals"
      },
      client: {
        fullName: "Full Name (Required)",
        fullNameError: "Full Name is",
        fullNamePlaceholder: "Enter your name",
        phone: "Phone (Required)",
        phoneError: "Phone is",
        invalidPhoneNumber: "Please enter a valid phone number.",
        phonePlaceholder: "Enter your phone number",
        address: "Address (Required)",
        addressError: "Address is",
        addressPlaceholder: "Enter your address",
        state: "State (Required)",
        stateError: "State is",
        country: "Country",
        required: "Required",
      },
    }
  },
  'ar': {
    global: {
      back: "عودة",
      lbp: "ليرة لبنانية",
      usd: "دولار أمريكي",
      brandsTitle: "العلامات التجارية",
      brandsSubtitle: "ابحث عن المنتجات حسب العلامات التجارية",
      productsTitle: "",
      productsSubtitle: "",
      noProductsFound: "لا توجد منتجات",
      noProductFound: "المنتج غير موجود",
      noBrandsFound: "لم يتم العثور على ماركات",
      noAwesomeFound: "لم يتم العثور على منتج رائع",
      noBestsFound: "لم يتم العثور على أفضل المنتجات مبيعًا",
      search: "بحث",
      soldOut: "بيعت كلها",
      goToHome: "اذهب إلى الصفحة الرئيسية",
      contactUs: "اتصل بنا",
      cart: "السلة",
      loading: "جار التحميل...",
    },
    homePage: {
      topSectionTitle: "Bella Balea",
      topSectionSubtitle: "تم تصميم موقع Bella Balea  لمنتجات التجميل الألمانية  عالية الجودة لتجعلك جميلة وساحرة طوال اليوم وأيضا  تبدين رائعة من الخارج كما تشعرين من الداخل.  موقع خاص مخصص لزبائن  الجملة حيث تجد جميع طلباتك من العناية بالجسم والشعر والبشرة واليدين والقدمين بأقل الأسعار،  وأفضل جودة",
      topSectionButton: "اذهب إلى المعرض",
      newProducts: "منتجات جديدة",
      newProductsSubtitle: "اعرض منتجاتنا الرائعة الجديدة",
      bestSeller: "الأكثر مبيعا",
      bestSellerSubtitle: "اعرض المنتجات الأكثر مبيعًا",
      showProduct: "عرض المنتج",
      quoteTitle: "معلومات عنا",
    },
    productPage: {
      addToCart: "أضف إلى السلة"
    },
    navbar: {
      home: "الصفحة الرئيسية",
      hairCare: "العناية بالشعر",
      skinCare: "العناية بالبشرة",
      lipsCare: "العناية بالشفاه",
      mouthCare: "العناية بالفم",
      bodyCare: "العناية بالجسم",
      handCare: "العناية باليدين",
      footCare: "العناية بالقدم",
      makeup: {
        title: "ميك أب",
        subcategories: {
          face: "الوجه",
          eyes: "العيون",
          lips: "الشفاه",
          nails: "الاضافر"
        }
      },
      perfumes: "عطور",
      foodSupplements: "المكملات الغذائية",
      cleaningTools: "ادوات التنظيف",
      others: "أشياء أخرى",
      brands: "العلامات التجارية"
    },
    footer: {
      aboutUs: "معلومات عنا",
      quickContact: "اتصال سريع",
      quickLinks: "روابط سريعة"
    },
    order: {
      success: "نجاح",
      addedToCart: "تمت إضافة المنتج إلى سلة التسوق الخاصة بك",
      yourCart: "عربتك",
      fillOutYourInformation: "املأ معلوماتك",
      done: "منته",
      submit: "اطلب الان!",
      submitTitle: "اطلب الان",
      submitSubtitle: "طلبك جاهز للمتابعة",
      totalPrice: "السعر الكلي:",
      back: "عودة",
      next: "التالي",
      cart: {
        name: "اسم",
        image: "صورة",
        price: "السعر",
        count: "عدد",
        total: "المجموع",
        totals: "المجاميع",
      },
      client: {
        fullName: "الاسم الكامل (مطلوب)",
        fullNameError: "الاسم الكامل",
        fullNamePlaceholder: "أدخل أسمك",
        phone: "الهاتف (مطلوب)",
        phoneError: "الهاتف",
        invalidPhoneNumber: "يرجى إدخال رقم هاتف صالح.",
        phonePlaceholder: "أدخل رقم هاتفك",
        address: "العنوان (مطلوب)",
        addressError: "العنوان",
        addressPlaceholder: "أدخل عنوانك",
        state: "المحافظة (مطلوب)",
        stateError: "المحافظة",
        country: "بلد",
        required: "مطلوب",
      },
    }
  },
}
