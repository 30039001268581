import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';
import { InQueryParams } from 'src/app/core/models/query-params';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { BrandsService } from 'src/app/core/services/brands.service';

interface SecondNavbarItem {
  display: string;
  onClick: () => void;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output('onNav') onNav:EventEmitter<void> = new EventEmitter<void>();
  readonly mainNavbarPosition = 0;
  readonly secondNavbarPosition = -199;
  subscription: Subscription;
  lang: string;
  value: number = 0;
  secondNavbarItems: Array<SecondNavbarItem> = [];
  secondNavbarElement: string = '';
  baleaItems: Array<SecondNavbarItem> = [];
  makeupItems: Array<SecondNavbarItem> = [];
  selectedMainNavbarItem: string = null;

  constructor(public i18nService: I18nService,
    private brandsService: BrandsService,
    private router: Router) { }

  ngOnInit(): void {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
        this.baleaItems = [
          {
            display: this.i18nService.data.navbar.hairCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'hair-care' })
          },
          {
            display: this.i18nService.data.navbar.skinCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'skin-care' })
          },
          {
            display: this.i18nService.data.navbar.lipsCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'lips-care' })
          },
          {
            display: this.i18nService.data.navbar.mouthCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'mouth-care' })
          },
          {
            display: this.i18nService.data.navbar.bodyCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'body-care' })
          },
          {
            display: this.i18nService.data.navbar.handCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'hand-care' })
          },
          {
            display: this.i18nService.data.navbar.footCare,
            onClick: () => this.goToProductsPage({ brand: this.baleaUID, category: 'foot-care' })
          },
        ];
        this.makeupItems = [
          {
            display: this.i18nService.data.navbar.makeup.subcategories.face,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Face' })
          },
          {
            display: this.i18nService.data.navbar.makeup.subcategories.eyes,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Eyes' })
          },
          {
            display: this.i18nService.data.navbar.makeup.subcategories.lips,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Lips' })
          },
          {
            display: this.i18nService.data.navbar.makeup.subcategories.nails,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Nails' })
          },
        ];
        switch (this.selectedMainNavbarItem) {
          case 'balea':
            this.secondNavbarItems = [...this.baleaItems];
            this.secondNavbarElement = '<img class="navbar__item_is-balea" src="../../../../assets/images/balea-without-bg.png" alt=""  width="100">';
            break;
          case 'makeup':
            this.secondNavbarItems = [...this.makeupItems];
            this.secondNavbarElement = this.i18nService.data.navbar.makeup.title;
            break;
          default:
            this.secondNavbarElement = '';
            this.secondNavbarItems = [];
        }
      }
    );
  }

  get baleaUID() {
    return this.brandsService.brands.find(({ name }) => name === 'Balea').uid;
  }

  goToHome() {
    this.router.navigateByUrl('/');
    this.onNav.emit();
  }

  openSecondNavbar(parent: string) {
    this.value = this.secondNavbarPosition;
    this.selectedMainNavbarItem = parent;
    switch (parent) {
      case 'balea':
        this.secondNavbarItems = [...this.baleaItems];
        this.secondNavbarElement = '<img src="../../../../assets/images/balea-without-bg.png" alt="" width="100">';
        break;
      case 'makeup':
        this.secondNavbarItems = [...this.makeupItems];
        this.secondNavbarElement = this.i18nService.data.navbar.makeup.title;
        break;
      default:
        this.secondNavbarItems = [];
        this.secondNavbarElement = '';
    }
  }

  backToMainNavbar() {
    this.value = this.mainNavbarPosition;
  }

  goToProductsPage( inQueryParams?: InQueryParams) {
    if (inQueryParams?.brand !== this.baleaUID && inQueryParams?.category !== 'makeup') {
      this.selectedMainNavbarItem = null;
    }
    let queryParams = {};
    if (inQueryParams?.category) {
      queryParams = {
        ...{ category: inQueryParams.category },
        ...queryParams,
      }
    }
    if (inQueryParams?.subcategory) {
      queryParams = {
        ...{ subcategory: inQueryParams.subcategory },
        ...queryParams,
      }
    }
    if (inQueryParams?.brand) {
      queryParams = {
        ...{ brand: inQueryParams.brand },
        ...queryParams,
      }
    }
    this.router.navigate(['/main/products'], { queryParams });
    this.onNav.emit();
  }

  goToBrandsPage() {
    this.router.navigateByUrl('/main/brands');
    this.onNav.emit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
