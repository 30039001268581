import { Component, OnInit } from '@angular/core';
import { ARABIC, ENGLISH, I18nService } from 'src/app/core/services/i18n.service';
import { InQueryParams } from 'src/app/core/models/query-params';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/core/services/settings.service';

interface SecondNavbarItem {
  display: string;
  onClick: () => void;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: string;
  lang: string;
  baleaItems: Array<SecondNavbarItem> = [];
  makeupItems: Array<SecondNavbarItem> = [];
  subscription: Subscription;
  dir: string;

  constructor(public i18nService: I18nService,
    private settingsService: SettingsService,
    private router: Router) { }

  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear().toString();
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
        this.dir = this.lang === ENGLISH ? 'ltr' : 'rtl';
        this.baleaItems = [
          {
            display: this.i18nService.data.navbar.hairCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'hair-care' })
          },
          {
            display: this.i18nService.data.navbar.skinCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'skin-care' })
          },
          {
            display: this.i18nService.data.navbar.lipsCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'lips-care' })
          },
          {
            display: this.i18nService.data.navbar.mouthCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'mouth-care' })
          },
          {
            display: this.i18nService.data.navbar.bodyCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'body-care' })
          },
          {
            display: this.i18nService.data.navbar.handCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'hand-care' })
          },
          {
            display: this.i18nService.data.navbar.footCare,
            onClick: () => this.goToProductsPage({ brand: 'Balea', category: 'foot-care' })
          },
        ];
        this.makeupItems = [
          {
            display: this.i18nService.data.navbar.makeup.subcategories.face,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Face' })
          },
          {
            display: this.i18nService.data.navbar.makeup.subcategories.eyes,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Eyes' })
          },
          {
            display: this.i18nService.data.navbar.makeup.subcategories.lips,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Lips' })
          },
          {
            display: this.i18nService.data.navbar.makeup.subcategories.nails,
            onClick: () => this.goToProductsPage({ category: 'makeup', subcategory: 'Nails' })
          },
        ];
      }
    );
  }

  get phone() {
    return this.settingsService.getPhone.settingValue;
  }

  get email() {
    return this.settingsService.getEmail.settingValue;
  }

  get aboutUs() {
    if (this.lang === ARABIC) {
      return this.settingsService.getAboutUsAr.settingValue;
    }
    return this.settingsService.getAboutUs.settingValue;
  }

  goToProductsPage( inQueryParams?: InQueryParams) {
    let queryParams = {};
    if (inQueryParams?.category) {
      queryParams = {
        ...{ category: inQueryParams.category },
        ...queryParams,
      }
    }
    if (inQueryParams?.subcategory) {
      queryParams = {
        ...{ subcategory: inQueryParams.subcategory },
        ...queryParams,
      }
    }
    if (inQueryParams?.brand) {
      queryParams = {
        ...{ brand: inQueryParams.brand },
        ...queryParams,
      }
    }
    this.router.navigate(['/main/products'], { queryParams });
  }

  goToHome() {
    this.router.navigateByUrl('/');
  }

  goToBrandsPage() {
    this.router.navigateByUrl('brands');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
