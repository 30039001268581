import { Component, OnInit } from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Observable } from 'rxjs';
import { ProductsService } from 'src/app/core/services/products.service';
import { Product } from 'src/app/core/models/product';

@Component({
  selector: 'best-seller-section',
  templateUrl: './best-seller-section.component.html',
  styleUrls: ['./best-seller-section.component.scss']
})
export class BestSellerSectionComponent implements OnInit {
  items$: Observable<Product[]>;
  constructor(public i18nService: I18nService,
    private productsServices: ProductsService) { }

  ngOnInit(): void {
    this.items$ = this.productsServices.getBestSellerProduct();
  }
}
