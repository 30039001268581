<div class="best-seller-section">
  <div class="best-seller-section__header">
    <div class="best-seller-section__header-title">
      {{i18nService.data.homePage.bestSeller}}
    </div>
    <div class="best-seller-section__header-subtitle">
      {{i18nService.data.homePage.bestSellerSubtitle}}
    </div>
  </div>
  <div class="best-seller-section__content">
    <div *ngIf="items$ | async; let products; else loading" class="best-seller-section__container">
      <div *ngIf="products.length; else noProducts" class="best-seller-section__grid">
        <!-- <best-seller-card
          *ngFor="let product of products"
          [product]=product
        >
        </best-seller-card> -->
        <product-card
          *ngFor="let product of products"
          [product]=product
        ></product-card>
      </div>
      <ng-template #noProducts>
        <div class="best-seller-section__empty-stats">
          <div class="best-seller-section__empty-stats-img">
            <img src="../../../assets/images/no-products-found.png" alt="">
          </div>
          <div class="best-seller-section__empty-stats-text">
            {{i18nService.data.global.noBestsFound}}
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #loading>
      <div class="best-seller-section__container">
        <div class="best-seller-section__grid">
          <animated-product-card *ngFor="let item of [].constructor(4)"></animated-product-card>
        </div>
      </div>
    </ng-template>
  </div>
</div>
