<div class="new-products-section">
  <!-- <section-title
    title={{i18nService.data.homePage.newProducts}}
    subtitle={{i18nService.data.homePage.newProductsSubtitle}}
    class="new-products-section__title"
  >
  </section-title> -->
  <div class="new-products-section__header">
    <div class="new-products-section__header-title">
      {{i18nService.data.homePage.newProducts}}
    </div>
    <div class="new-products-section__header-subtitle">
      {{i18nService.data.homePage.newProductsSubtitle}}
    </div>
  </div>
  <div class="new-products-section__content">
    <div *ngIf="items$ | async; let products; else loading" class="new-products-section__container">
      <div *ngIf="products.length; else noProducts" class="new-products-section__product">
        <new-product-card
          *ngFor="let product of products"
          [product]=product
        >
        </new-product-card>
      </div>
      <ng-template #noProducts>
        <div class="new-products-section__empty-stats">
          <div class="new-products-section__empty-stats-img">
            <img src="../../../assets/images/no-products-found.png" alt="">
          </div>
          <div class="new-products-section__empty-stats-text">
            {{i18nService.data.global.noAwesomeFound}}
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #loading>
      <div class="new-products-section__container">
        <div class="new-products-section__product">
          <new-product-card-loading></new-product-card-loading>
        </div>
      </div>
    </ng-template>
  </div>
</div>
