import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Language } from 'src/app/core/models/language';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

interface LanguageEventEmitter {
  source: unknown;
  value: string;
}

@Component({
  selector: 'language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})

export class LanguageSwitchComponent implements OnInit {
  subscription: Subscription;
  currentLang: string;
  languages: Language[] = [];
  faLanguage = faLanguage;
  dir: string;

  constructor(private i18nService: I18nService) { }

  ngOnInit(): void {
    this.languages = this.i18nService.languages;
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.currentLang = lang;
        this.dir = this.currentLang === 'en' ? 'ltr' : 'rtl';
      }
    );
  }

  selectLang($event: LanguageEventEmitter) {
    this.i18nService.setLang($event.value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
