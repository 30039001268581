import { Component, OnInit } from '@angular/core';
import { BrandsService } from 'src/app/core/services/brands.service';
import { Brand } from 'src/app/core/models/brand';
import { Router } from '@angular/router';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  brands: Brand[];

  constructor(public i18nService: I18nService,
      private brandService: BrandsService,
      private router: Router) { }

  ngOnInit(): void {
    this.brands = this.brandService.brands;
  }

  goToProduct($event: Brand) {
    this.router.navigate(['/main/products'], { queryParams: { brand: $event.uid } });
  }
}
