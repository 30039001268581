import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ProductsComponent } from './pages/products/products.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { ProductComponent } from './pages/product/product.component';
import { PagesComponent } from './pages/pages.component';
import { LoginPageComponent } from './admin/login-page/login-page.component';
import { ForgotPasswordPageComponent } from './admin/forgot-password-page/forgot-password-page.component';


const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'forgot-password', component: ForgotPasswordPageComponent },
  {
    path: 'main',
    component: PagesComponent,
    children: [
      { path: '', redirectTo: 'index', pathMatch: 'full' },
      { path: 'index', component: HomePageComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'product', component: ProductComponent },
      { path: 'brands', component: BrandsComponent },
    ]
  },
  { path: '404', component: NotFoundPageComponent },
  // { path: 'no-connection', component: NoConnectionComponent },
  { path: '#', redirectTo: '/main' },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
