<div class="navbar">
  <div class="navbar__spacer_flex_1"></div>
  <div class="navbar__items-container" [style.transform]="'translateX('+value+'px)'">
    <div class="navbar__main-navbar">
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToHome()"
      >
        {{i18nService.data.navbar.home}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="openSecondNavbar('balea')"
      >
        <img class="navbar__item_is-balea" src="../../../../assets/images/balea-without-bg.png" alt="">
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'hair-care' })"
      >
        {{i18nService.data.navbar.hairCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'skin-care' })"
      >
        {{i18nService.data.navbar.skinCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'lips-care' })"
      >
        {{i18nService.data.navbar.lipsCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'mouth-care' })"
      >
        {{i18nService.data.navbar.mouthCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'body-care' })"
      >
        {{i18nService.data.navbar.bodyCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'hand-care' })"
      >
        {{i18nService.data.navbar.handCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'foot-care' })"
      >
        {{i18nService.data.navbar.footCare}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="openSecondNavbar('makeup')"
      >
        {{i18nService.data.navbar.makeup.title}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'perfumes' })"
      >
        {{i18nService.data.navbar.perfumes}}
      </div>
      <div
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)="goToBrandsPage()"
      >
        {{i18nService.data.navbar.brands}}
      </div>

    </div>
    <div class="navbar__second-navbar">
      <div class="navbar__second-navbar-header" [innerHtml]="secondNavbarElement"></div>
      <div
        *ngFor="let item of secondNavbarItems"
        class="navbar__item"
        [ngClass]="{'navbar__item_letter-spacing_3': lang === 'en'}"
        (click)=item.onClick()
      >
        {{item.display}}
      </div>
      <div class="navbar__back-button" (click)="backToMainNavbar()">
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
        >
          arrow_back
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="navbar__spacer_flex_4"></div>
  <div class="navbar__social-media-bar">
    <social-media-bar></social-media-bar>
  </div>
  <div class="navbar__language-switch">
    <language-switch></language-switch>
  </div>
</div>
