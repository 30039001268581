import { Component, HostListener } from '@angular/core';
import { I18nService } from './core/services/i18n.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public i18nService: I18nService,) {
    if (localStorage.getItem('currentLang') == null) {
      localStorage.setItem('currentLang', 'ar');
    }
    this.i18nService.loadData();
  }

  ngOnInit() {
  }
}
