<div class="products-filter" [ngClass]="classModifiers">
  <div *ngIf="showHeader" class="products-filter__data-header">
    <div *ngIf="inQueryParams$ | async; let inQueryParams" class="products-filter__data-header_data">
      <div *ngIf="inQueryParams.brand" class="products-filter__data-header_item products-filter__data-header_brand">{{getBrand(inQueryParams.brand)}}</div>
      <div *ngIf="inQueryParams.brand && (inQueryParams.category || inQueryParams.subcategory)" class="products-filter__data-header_data-separator"></div>
      <div *ngIf="inQueryParams.category" class="products-filter__data-header_item products-filter__data-header_category">{{getCategory(inQueryParams.category)}}</div>
      <div *ngIf="inQueryParams.subcategory" class="products-filter__data-header_item products-filter__data-header_subcategory">{{getSubcategory(inQueryParams.subcategory)}}</div>
    </div>
    <div class="products-filter__search">
      <app-search (onSearch)="search($event)" [clearAfterSearch]="true" [isHidden]="hideSearch"></app-search>
    </div>
  </div>
  <div class="products-filter__categories-filter-container">
    <div
      *ngFor="let item of categories"
      class="products-filter__categories-filter-item"
      [ngClass]="{'products-filter__categories-filter-item_is-selected': selectedCategory?.name === item.name}"
      (click)="clickCategory(item)">
      <img [src]=item.icon alt="">
    </div>
  </div>
  <div *ngIf="selectedCategory && selectedCategory.subcategories" class="products-filter__categories-filter-container">
    <div
      *ngFor="let item of selectedCategory.subcategories"
      class="products-filter__subcategories-filter-item"
      [ngClass]="{'products-filter__subcategories-filter-item_is-selected': selectedSubcategory?.name === item.name}"
      (click)="clickSubcategory(item)">
      <img [src]=item.icon alt="">
    </div>
  </div>
</div>
