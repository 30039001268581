import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import Category, { Subcategory } from 'src/app/core/models/category';
import { InQueryParams } from 'src/app/core/models/query-params';
import { BrandsService } from 'src/app/core/services/brands.service';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { capitalize } from 'src/app/core/utils/capitalize-query-params';

interface CategoryItem {
  name: string;
  display: string;
}

@Component({
  selector: 'products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.scss']
})
export class ProductsFilterComponent implements OnInit {
  @Input('showHeader') showHeader = false;
  @Input('modifiers') modifiers: string = '';
  @Input('hideSearch') hideSearch: boolean = false;
  @Input('selectedCategory') selectedCategory: Category = null;
  @Input('selectedSubcategory') selectedSubcategory: Subcategory = null;
  @Output('onSearch') onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output('onClickCategory') onClickCategory: EventEmitter<Category> = new EventEmitter<Category>();
  @Output('onClickSubcategory') onClickSubcategory: EventEmitter<Subcategory> = new EventEmitter<Subcategory>();
  inQueryParams$: Observable<InQueryParams> = null;
  categories: Array<Category> = [];
  lang: string = 'en';
  subscription: Subscription;
  categoryItems: CategoryItem[] = [];
  subcategoryItems: CategoryItem[] = [];

  constructor(
    public i18nService: I18nService,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private brandsService: BrandsService
  ) { }

  ngOnInit(): void {
    this.categories = this.categoriesService.categories;
    this.inQueryParams$ = this.route.queryParams;
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
        this.categoryItems = [
          {
            name: 'Hair Care',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Skin Care',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Lips Care',
            display: this.i18nService.data.navbar.lipsCare,
          },
          {
            name: 'Mouth Care',
            display: this.i18nService.data.navbar.mouthCare,
          },
          {
            name: 'Body Care',
            display: this.i18nService.data.navbar.bodyCare,
          },
          {
            name: 'Hand Care',
            display: this.i18nService.data.navbar.handCare,
          },
          {
            name: 'Foot Care',
            display: this.i18nService.data.navbar.footCare,
          },
          {
            name: 'Makeup',
            display: this.i18nService.data.navbar.makeup.title
          },
          {
            name: 'Perfumes',
            display: this.i18nService.data.navbar.perfumes
          },
          {
            name: 'Food Supplements',
            display: this.i18nService.data.navbar.foodSupplements
          },
          {
            name: 'Cleaning Tools',
            display: this.i18nService.data.navbar.cleaningTools
          },
          {
            name: 'Others',
            display: this.i18nService.data.navbar.others
          }
        ];
        this.subcategoryItems = [
          {
            name: 'Face',
            display: this.i18nService.data.navbar.makeup.subcategories.face,
          },
          {
            name: 'Eyes',
            display: this.i18nService.data.navbar.makeup.subcategories.eyes,
          },
          {
            name: 'Lips',
            display: this.i18nService.data.navbar.makeup.subcategories.lips,
          },
          {
            name: 'Nails',
            display: this.i18nService.data.navbar.makeup.subcategories.nails,
          }
        ];
      }
    );
  }

  search(search: string) {
    this.onSearch.emit(search);
  }

  clickCategory(category: Category) {
    this.onClickCategory.emit(category);
  }

  clickSubcategory(subcategories: Subcategory) {
    this.onClickSubcategory.emit(subcategories);
  }

  getBrand(brandUid: string) {
    return this.brandsService.brands.find(({ uid }) => uid === brandUid).name.trim();
  }

  getCategory(category: string) {
    if (this.lang === 'en') {
      return capitalize(category);
    } else {
      return this.categoryItems.find(c => c.name === capitalize(category)).display;
    }
  }

  getSubcategory(subcategory: string) {
    if (this.lang === 'en') {
      return capitalize(subcategory);
    } else {
      return this.subcategoryItems.find(s => s.name === capitalize(subcategory)).display;
    }
  }

  get classModifiers() {
    if (this.modifiers && this.modifiers !== '') {
      return `product-filter_${this.modifiers}`;
    }
    return '';
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
