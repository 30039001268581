import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services/settings.service';
import { ARABIC, ENGLISH, I18nService } from 'src/app/core/services/i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  subscription: Subscription;
  lang: string;

  constructor(private settingsService: SettingsService, public i18nService: I18nService,) { }

  ngOnInit(): void {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    );
  }

  get quote() {
    return this.lang === ENGLISH
      ? this.settingsService.getQuote.settingValue
      : this.settingsService.getQuoteAr.settingValue;
  }

  get isArabic() {
    return this.lang === ARABIC;
  }
}
