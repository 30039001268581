
<div class="products">
  <div class="product__header-mobile">
    <app-search (onSearch)="search($event)" [clearAfterSearch]="true"></app-search>
  </div>
  <div class="products__header">
    <div class="products__header-chips">
      <app-chips-bar [chips]="chips"></app-chips-bar>
    </div>
    <!-- <input
      #inputSearch
      class="products__header-search"
      type="search"
      placeholder="Search..."
      aria-label="Search"
      (keyup)="searchProduct($event, inputSearch.value)"
    > -->
    <app-search class="products__header-search" (onSearch)="search($event)" [clearAfterSearch]="true"></app-search>
  </div>
  <products-filter
    [selectedCategory]="selectedCategory"
    [selectedSubcategory]="selectedSubcategory"
    (onSearch)="search($event)"
    (onClickCategory)="onClickCategory($event)"
    (onClickSubcategory)="onClickSubcategory($event)"
  ></products-filter>
  <div *ngIf="(items$ | async); let products;" class="products__container">
    <div *ngIf="products.length > 0; else loading" class="product__grid">
      <product-card
        *ngFor="let product of products"
        [product]=product
      >
      </product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
    </div>
    <ng-template #loading>
      <div *ngIf="loading$ | async;" class="product__grid">
        <animated-product-card *ngFor="let item of [].constructor(8)"></animated-product-card>
      </div>
      <div *ngIf="!(loading$ | async);" class="products__container">
        <div class="products__empty-stats">
          <div class="products__empty-stats-img">
            <img src="../../../assets/images/no-products-found.png" alt="">
          </div>
          <div class="products__empty-stats-text">
            {{i18nService.data.global.noProductsFound}}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
