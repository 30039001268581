import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  subscription: Subscription;
  currentLang: string;
  dir: string;

  constructor(private i18nService: I18nService) { }

  ngOnInit(): void {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.currentLang = lang;
        this.dir = this.currentLang === 'en' ? 'ltr' : 'rtl';
      }
    );
  }

}
