<div class="dashboard-storage-capacity">
  <div class="dashboard-storage-capacity__storage-chart-container">
    <div class="dashboard-storage-capacity__storage-title">
      Storage Free Plan
    </div>
    <p-knob
      [ngModel]="storage"
      [valueColor]="valueColor"
      rangeColor="lightgray"
      [readonly]="true"
      [size]="chartSize"
      [max]="5"
      [strokeWidth]="10"
      valueTemplate="{value}GB"
      [textColor]="textColor"
    >
    </p-knob>
    <div class="dashboard-storage-capacity__storage-state">
      <a
        style="margin-right: 10px;"
        href="https://console.firebase.google.com/u/6/project/bella-balea/overview"
        target="_blank"
      >
        Storage State
      </a>
      <b style="font-size: 16px;">&</b>
      <a
      style="margin-left: 10px;"
        href="https://console.firebase.google.com/u/6/project/bella-balea/usage"
        target="_blank"
      >
        Usage and Billing
      </a>
    </div>
  </div>
  <div class="dashboard-storage-capacity__storage-infos">
    <p>
      Cloud Storage free limits are enforced daily and refreshed at midnight Pacific Time.
      <br /><br />In the <a href="https://firebase.google.com/pricing" target="_blank">Blaze</a> plan, fees for Cloud Storage are based on usage volume.
      Cloud Storage usage fees are processed as Google Cloud App Engine usage fees for the
      default bucket, and Cloud Storage usage fees for any additional buckets.
      <br /><br />For more information, see pricing for
      <a href="https://cloud.google.com/appengine/pricing" target="_blank">Google Cloud App Engine</a> and
      <a href="https://cloud.google.com/storage/pricing" target="_blank">Cloud Storage</a>.
    </p>
  </div>
</div>
