import { Component, OnInit } from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';
import { InQueryParams } from 'src/app/core/models/query-params';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  subscription: Subscription;
  lang: string;
  constructor(public i18nService: I18nService,
    private router: Router) { }

  ngOnInit(): void {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    );
  }

  goToProductsPage( inQueryParams?: InQueryParams) {
    let queryParams = {};
    if (inQueryParams?.category) {
      queryParams = {
        ...{ category: inQueryParams.category },
        ...queryParams,
      }
    }
    this.router.navigate(['/main/products'], { queryParams });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
