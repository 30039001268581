<div class="settings">
  <div class="settings__container">
    <div class="settings__header">
      <div class="settings__title">
        Settings
      </div>
      <button
        pButton
        pRipple
        form="settings-form"
        type="button"
        label="Save"
        [disabled]="isLoading || !settingsForm.dirty"
        class="p-button-success p-button-text settings__slider-item-save"
        (click)="save()"
      >
      </button>
    </div>
    <div class="settings__content">
      <form *ngIf="!isLoading; else loading" id="settings-form" [formGroup]="settingsForm" class="settings__form">
        <div class="settings__form-radio">
          <label for="currency" class="settings__form-radio-label">The active currency for the product prices:</label>
          <mat-radio-group id="currency" aria-label="Select a currency" formControlName="currency">
            <mat-radio-button class="settings__form-radio-lbp" value="LBP">LBP</mat-radio-button>
            <mat-radio-button class="settings__form-radio-usd" value="USD">USD</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>LBP Rate</mat-label>
          <input matInput type="number" formControlName="lbp" placeholder="Enter the exchange rate of the LB Pound against the USD" [errorStateMatcher]="matcher">
          <span matSuffix>LBP</span>
          <mat-error *ngIf="form.lbp.errors?.required">
            LBP exchange rate is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.lbp.errors?.min">
            Invalid rate
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Max Product & Brand image size in bytes <b>({{form.regularSize.value | storageSize}})</b></mat-label>
          <input matInput type="number" formControlName="regularSize" placeholder="Enter the Max Product & Brand image size (Bytes)" [errorStateMatcher]="matcher">
          <span matSuffix>Bytes</span>
          <mat-error *ngIf="form.regularSize.errors?.required">
            Max Product & Brand image size is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.regularSize.errors?.min || form.regularSize.errors?.max">
            Size should be between 5KB (5,120 bytes) & 3MB (3,145,728 bytes)
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Max big image size in bytes <b>({{form.bigSize.value | storageSize}})</b></mat-label>
          <input matInput type="number" formControlName="bigSize" placeholder="Enter the Max big image size (Bytes)" [errorStateMatcher]="matcher">
          <span matSuffix>Bytes</span>
          <mat-error *ngIf="form.bigSize.errors?.required">
            Max Product & Brand image size is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.bigSize.errors?.min || form.bigSize.errors?.max">
            Size should be between 5KB (5,120 bytes) & 5MB (5,242,880 bytes)
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Top Section Title</mat-label>
          <textarea matInput formControlName="topSectionTitle"></textarea>
          <mat-error *ngIf="form.topSectionTitle.errors?.required">
            Top Section Title is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Top Section Title (Arabic)</mat-label>
          <textarea matInput formControlName="topSectionTitleAr"></textarea>
          <mat-error *ngIf="form.topSectionTitleAr.errors?.required">
            Top Section Title arabic is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Top Section Description</mat-label>
          <textarea matInput formControlName="topSectionDescription"></textarea>
          <mat-error *ngIf="form.topSectionDescription.errors?.required">
            Top Section Description is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Top Section Description (Arabic)</mat-label>
          <textarea matInput formControlName="topSectionDescriptionAr"></textarea>
          <mat-error *ngIf="form.topSectionDescriptionAr.errors?.required">
            Top Section Description arabic is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Quote</mat-label>
          <textarea matInput formControlName="quote"></textarea>
          <mat-error *ngIf="form.quote.errors?.required">
            Quote is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Quote (Arabic)</mat-label>
          <textarea matInput formControlName="quoteAr"></textarea>
          <mat-error *ngIf="form.quoteAr.errors?.required">
            Quote arabic is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>About Us</mat-label>
          <textarea matInput formControlName="aboutUs"></textarea>
          <mat-error *ngIf="form.aboutUs.errors?.required">
            About us is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>About Us (Arabic)</mat-label>
          <textarea matInput formControlName="aboutUsAr"></textarea>
          <mat-error *ngIf="form.aboutUsAr.errors?.required">
            About us arabic is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Google Map Url</mat-label>
          <input matInput formControlName="map" [errorStateMatcher]="matcher"
              placeholder="Enter the google map url of steel and signs location" [readonly]="true">
          <mat-error *ngIf="form.map.errors?.required">
            Google Map Url is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Whatsapp Number One</mat-label>
          <input matInput phoneMask formControlName="whatsappOne" [errorStateMatcher]="matcher"
            placeholder="Enter the first bella balea whatsapp number that started by + and the country code. e.g. +96170999999">
          <mat-error *ngIf="form.whatsappOne.errors?.required">
            Whatsapp number is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.whatsappOne.errors?.pattern">
            Please, enter a valid phone number that started by + and the country code. e.g. +96170999999
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Whatsapp Number Two</mat-label>
          <input matInput phoneMask formControlName="whatsappTwo" [errorStateMatcher]="matcher"
            placeholder="Enter the second bella balea whatsapp number that started by + and the country code. e.g. +96170999999">
          <mat-error *ngIf="form.whatsappTwo.errors?.required">
            Whatsapp number is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.whatsappTwo.errors?.pattern">
            Please, enter a valid phone number that started by + and the country code. e.g. +96170999999
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Facebook Link</mat-label>
          <input matInput formControlName="facebook" [errorStateMatcher]="matcher"
            placeholder="Enter the steel and signs facebook page url">
          <mat-error *ngIf="form.facebook.errors?.required">
            Facebook Link is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Instagram Link</mat-label>
          <input matInput formControlName="instagram" [errorStateMatcher]="matcher"
            placeholder="Enter the steel and signs instagram page url">
          <mat-error *ngIf="form.instagram.errors?.required">
            Instagram Link is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" [errorStateMatcher]="matcher"
            placeholder="Enter the steel and signs phone number">
          <mat-error *ngIf="form.phone.errors?.required">
            Phone number is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" [errorStateMatcher]="matcher"
            placeholder="Enter the steel and signs email">
          <mat-error *ngIf="form.email.errors?.required">
            Email is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.email.errors?.email">
            Please, enter a valid email.
          </mat-error>
        </mat-form-field>


        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Order text</mat-label>
          <textarea matInput formControlName="orderText"></textarea>
          <mat-error *ngIf="form.orderText.errors?.required">
            Order text is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="settings__form-field">
          <mat-label>Order text (arabic)</mat-label>
          <textarea matInput formControlName="orderTextAr"></textarea>
          <mat-error *ngIf="form.orderTextAr.errors?.required">
            Order text arabic is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>
      <ng-template #loading>
        <div class="settings__loading">
          <mat-progress-spinner
            class="login__spinner"
            diameter="50"
            mode="indeterminate"
            value="80">
          </mat-progress-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
