<div class="product-item">
  <div class="product-item__image-container">
    <img
      [hidden]="!isImgLoaded"
      [src]="product.imageUrl"
      alt=""
      class="product-item__image"
      (load)="isImgLoaded = true"
      (click)="clickImage()"
    >
    <img
      *ngIf="!isImgLoaded"
      src="assets/images/image-place-holder.png"
      class="product-item__image"
    >
  </div>
  <div
    class="product-item__left"
    [ngClass]="{'product-item__left_flex_1': !product.description || product.description === ''}"
  >
    <span class="product-item__name">
      {{product.name}}
      <mat-icon
        aria-hidden="false"
        aria-label="Copy Link"
        matTooltip="Copy Link"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
        class="product-item__copy-link-icon"
        [cdkCopyToClipboard]="productLink"
      >
        content_copy
      </mat-icon>
      <a
        [href]="whatsappProductLink"
        class="product-item__share-link-icon"
        data-action="share/whatsapp/share"
      >
        <fa-icon
          [icon]="faWhatsapp"
          size="x"
        >
        </fa-icon>
      </a>
    </span>
    <span class="product-item__size">
      <b>Image size:</b> {{product.imageSize | storageSize}}
    </span>
    <span *ngIf="product.sale" class="product-item__sale"><b>Sale: </b>{{product.sale}}%</span>
    <span class="product-item__price">Price: <b class="product-item__price-value">{{product.price}}$</b></span>
  </div>
  <div *ngIf="product.description && product.description !== ''" class="product-item__middle">
    <p class="product-item__description">{{product.description}}</p>
  </div>
  <div class="product-item__right">
    <div *ngIf="!isRunning; else running" class="product-item__actions">
      <a *ngIf="canAddAsSpecial" href="javascript:void(0)" class="product-item__add-as-special" (click)="addAsSpecial()">Add as a special</a>
      <a *ngIf="isEditable" href="javascript:void(0)" class="product-item__edit" (click)="edit()">Edit</a>
      <a *ngIf="canDelete" href="javascript:void(0)" class="product-item__delete" (click)="delete($event)">{{deleteLabel}}</a>
      <!-- <p-confirmPopup></p-confirmPopup> -->
    </div>
    <a *ngIf="canAddAsSpecial" href="javascript:void(0)" class="product-item__add-as-special-tablet" (click)="addAsSpecial()">Add as a special</a>
    <div class="product-item__sold-out">
      <mat-icon
        *ngIf="!product.isSoldOut"
        class="product-item__sold-out-icon"
        aria-hidden="false"
        aria-label="is sold out"
        >
        close
      </mat-icon>
      <mat-icon
        *ngIf="product.isSoldOut"
        class="product-item__sold-out-icon-checked"
        aria-hidden="false"
        aria-label="is sold out"
        >
        check
      </mat-icon>
      <span>Sold out</span>
    </div>
    <!-- <mat-slide-toggle
      [checked]="product.isSoldOut"
      [disabled]="true"
      color="primary"
    >
      Sold Out
    </mat-slide-toggle> -->
    <ng-template #running>
      <div class="product-item__actions"><skeleton height="15px" width="250px"></skeleton></div>
    </ng-template>
    <div class="product-item__brand-category">
      <span
        *ngIf="product.isBest"
        class="product-item__best-seller"
      >
        Best Seller
      </span>
      <span
        class="product-item__brand"
      >
        {{brand.name | uppercase}}
      </span>
      <span
        class="product-item__category"
      >
        {{product.subcategory ? product.category + ": " + product.subcategory : product.category| uppercase}}
      </span>
    </div>
  </div>
</div>
