<div class="products-manager">
  <div #container class="products-manager__container">
    <div class="products-manager__header">
      <div class="products-manager__title">
        Products
      </div>
      <app-chips-bar class="products-manager__chips-bar" [chips]="chips"></app-chips-bar>

      <mat-checkbox
          class="products-manager__by-barcode-checkbox"
          [(ngModel)]="searchByBarcode"
          labelPosition="after"
          [disabled]="false">
        By Barcode
      </mat-checkbox>
      <app-search (onSearch)="search($event)" [clearAfterSearch]="true"></app-search>
      <p-button
        icon="pi pi-plus"
        class="products-manager__new-product-button"
        (click)="displayModal=true"
        label="New Product"
        styleClass="p-button-text p-button-success">
      </p-button>
    </div>

    <div class="products-manager__content">
      <div #productsFilter class="products-manager__products-filter">
        <products-filter
          modifiers="min-height_0"
          [hideSearch]="displayModal"
          [selectedCategory]="selectedCategory"
          [selectedSubcategory]="selectedSubcategory"
          (onSearch)="search($event)"
          (onClickCategory)="onClickCategory($event)"
          (onClickSubcategory)="onClickSubcategory($event)"
        ></products-filter>
      </div>
      <div #products *ngIf="(items$ | async); let products;" class="products-manager__products-container">
        <div *ngIf="products.length > 0; else loading" class="products-manager__list">
          <product-item
            *ngFor="let product of products"
            [product]=product
            (onEdit)="editProduct(product)"
            (onAddAsSpecial)="selectSpecial(product)"
            (onDelete)="deleteProduct(product)"
            [isRunning]="isLoading"
          >
          </product-item>
          <div class="products-manager__show-more-button-container">
            <button
              *ngIf="!(loading$ | async) && !isDone"
              class="products-manager__show-more-button"
              mat-flat-button
              (click)="fetchMore()"
            >
              Show More
            </button>
          </div>
          <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
          <div style="height: 20px;"></div>
        </div>
        <ng-template #loading>
          <div class="products-manager__list">
            <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
            <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
            <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
            <div *ngIf="!(loading$ | async);" class="products-manager__empty-state-container">
              <div class="products-manager__empty-stats-text">
                No Products found.
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{selectedProduct ? 'Edit Product.' : 'New Product.'}}"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{width: '50vw'}"
  [draggable]="false"
  [resizable]="false"
  [autoZIndex]="false"
  (onHide)="hideModal($event)"
>
  <form id="product-form" [formGroup]="productForm" class="products-manager__form">

    <mat-slide-toggle
      class="products-manager__sold-out-field"
      formControlName="isSoldOut"
      color="primary"
    >
      Sold Out
    </mat-slide-toggle>

    <mat-form-field appearance="fill" class="products-manager__category-field">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category" (selectionChange)="onCategoryChange($event)">
        <mat-option *ngFor="let category of categories" [value]="category">
          {{category.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="productForm.get('category')?.hasError('required')">
        Category is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="productForm.get('category').value?.subcategories" appearance="fill" class="products-manager__category-field">
      <mat-label>Subcategory</mat-label>
      <mat-select formControlName="subcategory">
        <mat-option *ngFor="let subcategory of productForm.get('category').value?.subcategories" [value]="subcategory">
          {{subcategory.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="productForm.get('subcategory')?.hasError('required')">
        Subcategory is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="products-manager__brand-field">
      <mat-label>Brand</mat-label>
      <mat-select formControlName="brand">
        <mat-option *ngFor="let brand of brands" [value]="brand">
          {{brand.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="productForm.get('brand')?.hasError('required')">
        Brand is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="products-manager__name-field">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" [errorStateMatcher]="matcher"
            placeholder="Product name">
      <mat-error *ngIf="productForm.get('name')?.hasError('required')">
        Product name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="products-manager__name-field">
      <mat-label>Name (Arabic)</mat-label>
      <input matInput formControlName="nameAr" [errorStateMatcher]="matcher"
            placeholder="Product name (Arabic)">
      <mat-error *ngIf="productForm.get('nameAr')?.hasError('required')">
        Product name (Arabic) is <strong>required</strong>
      </mat-error>
    </mat-form-field>

      <mat-form-field appearance="fill" class="products-manager__description-field">
      <mat-label>Description <span class="products-manager__optional-field"></span></mat-label>
      <textarea matInput formControlName="description"></textarea>
      <mat-error *ngIf="productForm.get('description')?.hasError('required')">
        Product description is <strong>required</strong>
      </mat-error>
    </mat-form-field>

      <mat-form-field appearance="fill" class="products-manager__description-field">
      <mat-label>Description (Arabic)<span class="products-manager__optional-field"></span></mat-label>
      <textarea matInput formControlName="descriptionAr"></textarea>
      <mat-error *ngIf="productForm.get('descriptionAr')?.hasError('required')">
        Product description (Arabic) is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="products-manager__price-field">
      <mat-label>Price</mat-label>
      <input matInput type="number" formControlName="price" placeholder="Product Price">
      <span matSuffix>$</span>
      <mat-error *ngIf="productForm.get('price')?.hasError('required')">
        Product price is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="productForm.get('price')?.hasError('min')">
        Invalid price
      </mat-error>
    </mat-form-field>

    <div class="products-manager__sale-field-container">
      <label class="products-manager__sale-field-label">Sale</label>
      <label class="products-manager__sale-field-value-label">{{productForm.get('sale').value}}%</label>
    </div>
    <mat-slider
      class="products-manager__sale-field"
      thumbLabel
      formControlName="sale"
      [displayWith]="formatLabel"
      tickInterval="1000"
      step="1"
      min="0"
      max="99"
      aria-label="units">
    </mat-slider>

    <mat-form-field appearance="fill" class="products-manager__barcode-field">
      <mat-label>Barcode (Optional)</mat-label>
      <input matInput formControlName="barcode" [errorStateMatcher]="matcher"
            placeholder="Barcode">
    </mat-form-field>

    <h5 style="margin-top: 10px;">The best seller products appear in the home page under the best sellers section.<br />You can add up to 4 products as best sellers.</h5>
    <mat-slide-toggle
      class="products-manager__best-seller-field"
      formControlName="isBest"
    >
      Best Seller
    </mat-slide-toggle>

    <div class="products-manager__file-upload">
      <h3>Product Image</h3>
      <mat-checkbox
        *ngIf="selectedProduct"
        color="primary"
        class="products-manager__update-image-checkbox"
        [(ngModel)]="updateImage"
        [ngModelOptions]="{standalone: true}"
      >
        Want to update the image
      </mat-checkbox>
      <p-fileUpload
        #fileUpload
        *ngIf="!selectedProduct || updateImage"
        accept="image/*"
        [maxFileSize]="regularSize"
        [customUpload]="true"
        [showUploadButton]="false"
        [showCancelButton]="false"
        (onSelect)="selectImage($event)"
        (onRemove)="removeImage($event)"
      ></p-fileUpload>
      <mat-error *ngIf="showUploadImageError">
        Product image is <strong>required</strong>
      </mat-error>
    </div>
    <div class="products-manager__actions">
      <p-button
        *ngIf="!isLoading; else spinner"
        icon="pi pi-check"
        form="product-form"
        (onClick)="createProduct()"
        [disabled]="!productForm.dirty && selectedImage === null"
        label="{{selectedProduct ? 'Edit' : 'Create'}}"
        styleClass="p-button-text">
      </p-button>
      <ng-template #spinner>
        <mat-progress-spinner
          class="products-manager__spinner"
          diameter="30"
          mode="indeterminate"
          value="80">
        </mat-progress-spinner>
      </ng-template>
    </div>
  </form>
</p-dialog>

<p-dialog
  header="Special Product"
  [(visible)]="displaySpecialModal"
  [modal]="true"
  [style]="{width: '50vw'}"
  [draggable]="false"
  [resizable]="false"
  [autoZIndex]="false"
  (onHide)="hideSpecialModal($event)"
>
  <div class="products-manager__file-upload">
    <mat-checkbox
      *ngIf="selectedSpecialProduct"
      color="primary"
      class="products-manager__update-image-checkbox"
      [(ngModel)]="updateSpecialImage"
      [ngModelOptions]="{standalone: true}"
    >
      Want to add a special post for the special new product?
    </mat-checkbox>
    <p-fileUpload
      #specialFileUpload
      *ngIf="updateSpecialImage"
      accept="image/*"
      [maxFileSize]="bigSize"
      [customUpload]="true"
      [showUploadButton]="false"
      [showCancelButton]="false"
      (onSelect)="selectSpecialImage($event)"
      (onRemove)="removeSpecialImage($event)"
    ></p-fileUpload>
  </div>
  <div class="products-manager__actions">
    <p-button
      *ngIf="!isLoading; else specialSpinner"
      icon="pi pi-check"
      (onClick)="createAwesomeProduct()"
      [disabled]="updateSpecialImage && selectedSpecialImage === null"
      label="Confirm"
      styleClass="p-button-text">
    </p-button>
    <ng-template #specialSpinner>
      <mat-progress-spinner
        class="products-manager__spinner"
        diameter="30"
        mode="indeterminate"
        value="80">
      </mat-progress-spinner>
    </ng-template>
  </div>
</p-dialog>
