import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LBP, SettingsService, USD } from 'src/app/core/services/settings.service';
import { AppErrorStateMatcher } from 'src/app/shared/utils/error-state-matcher';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  isLoading = false;
  matcher = new AppErrorStateMatcher();

  settingsForm = this.fb.group({
    currency: ['USD', Validators.required],
    lbp: [0, [Validators.required, Validators.min(0)]],
    regularSize: [0, [Validators.required, Validators.min(5120), Validators.max(3145728)]],
    bigSize: [0, [Validators.required, Validators.min(5120), Validators.max(5242880)]],
    topSectionDescription: ['', Validators.required],
    topSectionDescriptionAr: ['', Validators.required],
    topSectionTitle: ['', Validators.required],
    topSectionTitleAr: ['', Validators.required],
    quote: ['', Validators.required],
    quoteAr: ['', Validators.required],
    aboutUs: ['', Validators.required],
    aboutUsAr: ['', Validators.required],
    map: ['', Validators.required],
    whatsappOne: ['', [Validators.required, Validators.pattern("^(\\+)[0-9\\s.\\/-]{6,20}$")]],
    whatsappTwo: ['', [Validators.required, Validators.pattern("^(\\+)[0-9\\s.\\/-]{6,20}$")]],
    facebook: ['', Validators.required],
    instagram: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    orderText: ['', Validators.required],
    orderTextAr: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.form.currency.setValue(this.settingsService.activeUnit.name);
    this.form.lbp.setValue(this.settingsService.lbpUnit.rate);
    this.form.regularSize.setValue(this.settingsService.getMaxRegularImageSize.settingValue);
    this.form.bigSize.setValue(this.settingsService.getMaxBigImageSize.settingValue);
    this.form.topSectionDescription.setValue(this.settingsService.getTopSectionDescription?.settingValue);
    this.form.topSectionDescriptionAr.setValue(this.settingsService.getTopSectionDescriptionAr?.settingValue);
    this.form.topSectionTitle.setValue(this.settingsService.getTopSectionTitle?.settingValue);
    this.form.topSectionTitleAr.setValue(this.settingsService.getTopSectionTitleAr?.settingValue);
    this.form.quote.setValue(this.settingsService.getQuote?.settingValue);
    this.form.quoteAr.setValue(this.settingsService.getQuoteAr?.settingValue);
    this.form.aboutUs.setValue(this.settingsService.getAboutUs?.settingValue);
    this.form.aboutUsAr.setValue(this.settingsService.getAboutUsAr?.settingValue);
    this.form.map.setValue(this.settingsService.getMap?.settingValue);
    this.form.whatsappOne.setValue(`+${this.settingsService.getWhatsappOne?.settingValue}`);
    this.form.whatsappTwo.setValue(`+${this.settingsService.getWhatsappTwo?.settingValue}`);
    this.form.facebook.setValue(this.settingsService.getFacebook?.settingValue);
    this.form.instagram.setValue(this.settingsService.getInstagram?.settingValue);
    this.form.phone.setValue(this.settingsService.getPhone?.settingValue);
    this.form.email.setValue(this.settingsService.getEmail?.settingValue);
    this.form.orderText.setValue(this.settingsService.getOrderText?.settingValue);
    this.form.orderTextAr.setValue(this.settingsService.getOrderTextAr?.settingValue);
  }

  get form() {
    return this.settingsForm.controls;
  }

  async save() {
    if (this.settingsForm.invalid) {
      this.settingsForm.markAllAsTouched();
      return;
    }
    try {
      this.isLoading = true;
      if (this.form.lbp.dirty || this.form.currency.dirty) {
        let lbpUnit = this.settingsService.lbpUnit;
        let usdUnit = this.settingsService.usdUnit;
        if (this.form.lbp.dirty) {
          lbpUnit.rate = this.form.lbp.value ?? 0;
        }
        if (this.form.currency.dirty) {
          usdUnit.isActive = this.form.currency.value === USD;
          lbpUnit.isActive = this.form.currency.value === LBP;
          await this.settingsService.updateUnits(lbpUnit);
          await this.settingsService.updateUnits(usdUnit);
        } else {
          await this.settingsService.updateUnits(lbpUnit);
        }
      }
      if (this.form.topSectionDescription.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getTopSectionDescription!,
          settingValue: this.form.topSectionDescription.value
        });
      }
      if (this.form.topSectionDescriptionAr.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getTopSectionDescriptionAr!,
          settingValue: this.form.topSectionDescriptionAr.value
        });
      }
      if (this.form.topSectionTitle.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getTopSectionTitle!,
          settingValue: this.form.topSectionTitle.value
        });
      }
      if (this.form.topSectionTitleAr.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getTopSectionTitleAr!,
          settingValue: this.form.topSectionTitleAr.value
        });
      }
      if (this.form.regularSize.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getMaxRegularImageSize!,
          settingValue: this.form.regularSize.value
        });
      }
      if (this.form.bigSize.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getMaxBigImageSize!,
          settingValue: this.form.regularSize.value
        });
      }
      if (this.form.quote.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getQuote!,
          settingValue: this.form.quote.value
        });
      }
      if (this.form.quoteAr.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getQuoteAr!,
          settingValue: this.form.quoteAr.value
        });
      }
      if (this.form.aboutUs.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getAboutUs!,
          settingValue: this.form.aboutUs.value
        });
      }
      if (this.form.aboutUsAr.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getAboutUsAr!,
          settingValue: this.form.aboutUsAr.value
        });
      }
      if (this.form.map.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getMap!,
          settingValue: this.form.map.value
        });
      }
      if (this.form.whatsappOne.dirty) {
        let number = this.form.whatsappOne.value as string;
        number = number.slice(1);
        number = number.split(' ').join('');
        number = number.split('-').join('');
        number = number.split('.').join('');
        await this.settingsService.updateSetting({
          ...this.settingsService.getWhatsappOne!,
          settingValue: number
        });
      }
      if (this.form.whatsappTwo.dirty) {
        let number = this.form.whatsappTwo.value as string;
        number = number.slice(1);
        number = number.split(' ').join('');
        number = number.split('-').join('');
        number = number.split('.').join('');
        await this.settingsService.updateSetting({
          ...this.settingsService.getWhatsappTwo!,
          settingValue: number
        });
      }
      if (this.form.facebook.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getFacebook!,
          settingValue: this.form.facebook.value
        });
      }
      if (this.form.instagram.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getInstagram!,
          settingValue: this.form.instagram.value
        });
      }
      if (this.form.phone.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getPhone!,
          settingValue: this.form.phone.value
        });
      }
      if (this.form.email.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getEmail!,
          settingValue: this.form.email.value
        });
      }
      if (this.form.orderText.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getOrderText!,
          settingValue: this.form.orderText.value
        });
      }
      if (this.form.orderTextAr.dirty) {
        await this.settingsService.updateSetting({
          ...this.settingsService.getOrderTextAr!,
          settingValue: this.form.orderTextAr.value
        });
      }
      this.init();
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  }
}
