import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopSectionComponent } from './top-section/top-section.component';
import { NewProductsSectionComponent } from './new-products-section/new-products-section.component';
import { BestSellerSectionComponent } from './best-seller-section/best-seller-section.component';
import { SharedModule } from '../shared/shared.module';
import { CategoriesComponent } from './categories/categories.component';
import { QuoteComponent } from './quote/quote.component';



@NgModule({
  declarations: [
    TopSectionComponent,
    NewProductsSectionComponent,
    BestSellerSectionComponent,
    CategoriesComponent,
    QuoteComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    TopSectionComponent,
    NewProductsSectionComponent,
    BestSellerSectionComponent,
    CategoriesComponent,
    QuoteComponent
  ]
})
export class SectionsModule { }
