import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavComponent } from './layouts/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AppLogoComponent } from './components/app-logo/app-logo.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { SocialMediaBarComponent } from './components/social-media-bar/social-media-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { BrandCardComponent } from './components/brand-card/brand-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { LocationButtonComponent } from './components/location-button/location-button.component';
import { SearchComponent } from './components/search/search.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { AnimatedProductCardComponent } from './components/animated-product-card/animated-product-card.component';
import { BestSellerCardComponent } from './components/best-seller-card/best-seller-card.component';
import { NewProductCardComponent } from './components/new-product-card/new-product-card.component';
import { NewProductCardLoadingComponent } from './components/new-product-card-loading/new-product-card-loading.component';
import { SalePipe } from './pipes/sale.pipe';
import { PricePipe } from './pipes/price.pipe';
import { MapComponent } from './components/map/map.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { StorageSizePipe } from './pipes/storage-size.pipe';
import { ProductsFilterComponent } from './components/products-filter/products-filter.component';
import { BrandsGridComponent } from './components/brands-grid/brands-grid.component';
import { NgPrimeModule } from '../ng-prime/ng-prime.module';
import { MaterialModule } from '../material/material.module';
import { ChipComponent } from './components/chip/chip.component';
import { MobileSearchComponent } from './components/mobile-search/mobile-search.component';
import { ChipsBarComponent } from './components/chips-bar/chips-bar.component';
import { CartTableComponent } from './components/cart-table/cart-table.component';
import { RouterModule } from '@angular/router';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { CartContentComponent } from './components/cart-content/cart-content.component';

@NgModule({
  declarations: [
    MainNavComponent,
    AppLogoComponent,
    SectionTitleComponent,
    FooterComponent,
    NavbarComponent,
    SocialMediaBarComponent,
    LanguageSwitchComponent,
    BrandCardComponent,
    ProductCardComponent,
    LocationButtonComponent,
    SearchComponent,
    CapitalizePipe,
    AnimatedProductCardComponent,
    BestSellerCardComponent,
    NewProductCardComponent,
    NewProductCardLoadingComponent,
    SalePipe,
    PricePipe,
    MapComponent,
    ShoppingCartComponent,
    PoweredByComponent,
    SkeletonComponent,
    StorageSizePipe,
    ProductsFilterComponent,
    BrandsGridComponent,
    ChipComponent,
    MobileSearchComponent,
    ChipsBarComponent,
    CartTableComponent,
    NumberInputComponent,
    OrderItemComponent,
    CartContentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    FontAwesomeModule,
    RouterModule,
    NgPrimeModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MainNavComponent,
    SectionTitleComponent,
    FooterComponent,
    BrandCardComponent,
    ProductCardComponent,
    LocationButtonComponent,
    SearchComponent,
    CapitalizePipe,
    AnimatedProductCardComponent,
    BestSellerCardComponent,
    NewProductCardComponent,
    NewProductCardLoadingComponent,
    SalePipe,
    PricePipe,
    MapComponent,
    ShoppingCartComponent,
    PoweredByComponent,
    SkeletonComponent,
    StorageSizePipe,
    ProductsFilterComponent,
    BrandsGridComponent,
    ChipComponent,
    MobileSearchComponent,
    ChipsBarComponent,
    CartTableComponent,
    NumberInputComponent,
    OrderItemComponent,
    CartContentComponent
  ]
})
export class SharedModule { }
