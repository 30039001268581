<div class="categories">
  <div class="categories__container">
  <div class="categories__grid">
    <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'hair-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/hair-cat.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.hairCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'skin-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/skin-cat.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.skinCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'lips-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/lips-cat.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.lipsCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'mouth-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/mouth-cat.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.mouthCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'body-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/body-cat.jpg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.bodyCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'hand-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/hand-cat.jpg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.handCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'foot-care' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/foot-cat.jpg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.footCare}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'makeup' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/makeup-cat.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.makeup.title}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'perfumes' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/perfume-cat.jpg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.perfumes}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'food-supplements' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/food_supplements.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.foodSupplements}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'cleaning-tools' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/cleaning-tools.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.cleaningTools}}</div>
      </div>
      <div
        class="categories__item"
        [ngClass]="{'categories__item_letter-spacing_3': lang === 'en'}"
        (click)="goToProductsPage({ category: 'others' })"
      >
        <div class="categories__image-box">
          <img class="categories__image" src="/assets/images/others.jpeg" alt="">
        </div>
        <div class="categories__name">{{i18nService.data.navbar.others}}</div>
      </div>
    </div>
  </div>
</div>
