<footer class="footer js-footer" dir={{dir}}>
  <div class="footer__container">
    <div class="footer__content">
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.quickLinks}}
        </div>
        <a class="footer__section-item" (click)="goToHome()">
          {{i18nService.data.navbar.home}}
        </a>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'hair-care' })"
        >
          {{i18nService.data.navbar.hairCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'skin-care' })"
        >
          {{i18nService.data.navbar.skinCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'lips-care' })"
        >
          {{i18nService.data.navbar.lipsCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'mouth-care' })"
        >
          {{i18nService.data.navbar.mouthCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'body-care' })"
        >
          {{i18nService.data.navbar.bodyCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'hand-care' })"
        >
          {{i18nService.data.navbar.handCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'foot-care' })"
        >
          {{i18nService.data.navbar.footCare}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'makeup' })"
        >
          {{i18nService.data.navbar.makeup.title}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToProductsPage({ category: 'perfumes' })"
        >
          {{i18nService.data.navbar.perfumes}}
        </div>
        <div
          class="footer__section-item"
          (click)="goToBrandsPage()"
        >
          {{i18nService.data.navbar.brands}}
        </div>
      </div>
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          Balea
        </div>
        <a
          *ngFor="let item of baleaItems"
          class="footer__section-item"
          (click)=item.onClick()
        >
          {{item.display}}
        </a>
      </div>
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.quickContact}}
        </div>
        <span class="footer__section-phone">
          <mat-icon [ngClass]="{'footer__section-phone_lang_ar': lang === 'ar'}" [inline]="true" aria-hidden="false" aria-label="phone" style="font-size: 20px; line-height: 20px; text-align: center; vertical-align: middle; margin-right: 10px;">
            phone
          </mat-icon>
          <span dir="ltr">{{phone}}</span>
        </span>
        <span class="footer__section-email">
          <mat-icon [ngClass]="{'footer__section-email_margin-left_10': lang === 'ar'}" [inline]="true" aria-hidden="false" aria-label="email" style="font-size: 20px; line-height: 20px; text-align: center; vertical-align: middle; margin-right: 10px;">
            email
          </mat-icon>
          <span>{{email}}</span>
        </span>
        <div class="footer__section-social-media">
          <social-media-bar></social-media-bar>
        </div>
      </div>
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.aboutUs}}
        </div>
        <p class="footer__section-about-us">
          {{aboutUs}}
        </p>
        <img src="../../../../assets/images/bblogo.png" alt="" class="footer__logo">
      </div>
    </div>
    <div class="footer__copy-right">
      Bella Balea © {{currentYear}}. Designed and developed by <a href="https://boomowl.com" class="footer__developer" target="_blank">boomowl</a>, Lebanon
    </div>
  </div>
</footer>
