<div>
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
  <div *ngIf="innerWidth > 500 || showFAB" class="fab">
    <button
      mat-mini-fab
      class="whatsapp__first-account"
      [ngClass]="{'whatsapp__first-account_is-visible': showWpButtons}"
      aria-label="whatsapp"
      (click)="launchUrl(whatsappOneLink)"
    >
      1
    </button>
    <button
      mat-mini-fab
      class="whatsapp__second-account"
      [ngClass]="{'whatsapp__second-account_is-visible': showWpButtons}"
      aria-label="whatsapp"
      (click)="launchUrl(whatsappTwoLink)"
    >
      2
    </button>
    <button
      mat-fab
      aria-label="whatsapp"
      class="whatsapp"
      (click)="toggleFabButtons()"
    >
      <fa-icon
        class="social-media-bar_icon"
        [icon]="faWhatsapp"
        size="2x"
      >
      </fa-icon>
    </button>
  </div>
</div>
<!-- <div [dir]="langDirection">
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
</div> -->
