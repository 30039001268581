<div class="quote">
  <div class="quote__header">
    <img class="quote__header-icon" src="/assets/images/spa-flower-outline.gif" alt="">
    <!-- <div class="quote__title" [ngClass]="{'quote__title_is-arabic': isArabic}">
      {{i18nService.data.homePage.quoteTitle}}
    </div> -->
  </div>
  <p class="quote__body">
    {{quote}}
  </p>
</div>
