<div class="top-section">
  <div class="top-section__container">
    <div class="top-section__content">
      <span class="top-section__title">
        {{topSectionTitle}}
      </span>
      <span class="top-section__subtitle">
        {{topSectionDescription}}
      </span>
      <div class="top-section__go-to-products" (click)="goToGallery()">
        {{i18nService.data.homePage.topSectionButton}}
      </div>
    </div>
  </div>
</div>
