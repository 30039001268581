import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  largeScreen = false;
  subscription: Subscription;
  breakpoint = '(min-width: 640px)'

  constructor(public breakpointObserver: BreakpointObserver) {
    this.subscription = breakpointObserver.observe([
      this.breakpoint
    ]).subscribe(result => {
      this.largeScreen = result.matches;
    });
  }

  ngOnInit(): void {
    this.largeScreen = this.breakpointObserver.isMatched(this.breakpoint);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
